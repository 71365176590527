export function Twitter () {
    window.open("https://twitter.com/QubeLedger");
  }

export function GitHub () {
    window.open("https://github.com/QubeLedger");
  }

export function Telegram () {
    window.open("https://t.me/QubeLedger");
  }
